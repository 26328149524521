import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../shared/entities/authentication.service';
import {AuthService, FacebookLoginProvider, GoogleLoginProvider, SocialUser} from 'angularx-social-login';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-wish',
  templateUrl: './wish.component.html',
  styleUrls: ['./wish.component.scss']
})
export class WishComponent implements OnInit, OnDestroy {
  @Input()
  closeModalCancel: () => void;

  @Input()
  closeModalSuccess: () => void;

  @Input()
  isModal = true;

  @Input()
  showRegister = true;

  @Input()
  openRegister: () => void;

  constructor(private fb: FormBuilder, private authenticationService: AuthenticationService, private authService: AuthService) {}

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

}
