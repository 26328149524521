import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RegistrationRequestComponent} from './registration-request.component';
import {ReactiveFormsModule} from '@angular/forms';
import {InputControlModule} from '../input-control/input-control.module';



@NgModule({
  imports: [CommonModule, ReactiveFormsModule, InputControlModule],
  declarations: [RegistrationRequestComponent],
  exports: [RegistrationRequestComponent]
})
export class RegistrationRequestModule {}
