import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../shared/entities/authentication.service';

@Component({
  selector: 'app-recover-pass',
  templateUrl: './recover-pass.component.html',
  styleUrls: ['./recover-pass.component.scss']
})
export class RecoverPassComponent implements OnInit {
  recoverForm: FormGroup;
  emailSent = false;

  @Input()
  closeModalCancel: () => void;

  constructor(private fb: FormBuilder, private authenticationService: AuthenticationService) {
    this.recoverForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
  }

  recoverPassword() {
    this.authenticationService.sendRecoveryPassword(this.recoverForm.value.email).subscribe(res => {
      this.emailSent = true;
    });
  }

}
