import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {GMenuNameModule} from '../modules/g-menu-name';

@Injectable({
  providedIn: 'root'
})
export class MenuEventsService {

  constructor(@Inject(PLATFORM_ID) private platformId: string) {
  }

  public reloadActions() {
    new GMenuNameModule(this.platformId).createMenus();
  }
}
