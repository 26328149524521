import {Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-happiness-level-detail',
  templateUrl: './happiness-level-detail.component.html',
  styleUrls: ['./happiness-level-detail.component.scss']
})
export class HappinessLevelDetailComponent implements OnInit, OnDestroy {

  @Input()
  closeModalCancelHLD: () => void;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {
  }

}
