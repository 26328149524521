import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-control',
  templateUrl: './input-control.component.html',
  styleUrls: ['./input-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputControlComponent),
      multi: true
    }
  ]
})
export class InputControlComponent implements OnInit, ControlValueAccessor {

  @Input() Label = '';
  @Input() Type  = '';
  value = '';
  isDisabled: boolean;

  pipeAmount = false;

  onChange = (_:any) => { }
  onTouch = () => { }

  constructor() { }

  ngOnInit() {
    this.setValue(this.Type);
  }

  onInput(value: string) {
    this.value = value;
    this.onTouch();
    this.onChange(this.value);
  }

  writeValue(value: any): void {
    if (value) {
      this.value = value || '';
    } else {
      this.value = '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  setValue(type) {
    if (this.Type === 'amount') {
      this.pipeAmount = true;
    }
  }

  setPipe(type) {
    if (this.Type === 'amount') {
      this.pipeAmount = false;
    }
  }

}
