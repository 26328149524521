import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SocialUser } from 'angularx-social-login';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoginAction, LoginActionType } from '../redux/login/login-action';
import { LoginStore } from '../redux/login/login-store';
import { BaseService } from './base.service';

export class User {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  token: string;
}

@Injectable({providedIn: 'root'})
export class AuthenticationService extends BaseService {

  constructor(protected http: HttpClient, private store: LoginStore) {
    super(http);
    this.basePath = 'authenticate';
  }

  login(username, password) {
    return this.http.post<any>(`${this.entityUrl}`, {username, password})
      .pipe(map(user => {
        const innerToken = user.token.split('.')[1];
        const authenticateUser = JSON.parse(atob(innerToken));

        this.store.dispatch(new LoginAction(LoginActionType.authenticated, { token: user.token, id: authenticateUser.id, authenticateUser, realEstate: authenticateUser.propertyCompaniesId, isRealState: !!authenticateUser.propertyCompaniesId }));
        return user;
      }));
  }

  reSendVerificationEmail(username) {
    const appUrl = environment.appUrl;
    return this.http.post<any>(`${this.entityUrl}/resend-email`, {username, appUrl});
  }

  register(userType: string, fullName: string, phone: string, username: string, password: string, companyName: string, ruc: string, address: string) {
    const appUrl = environment.appUrl;
    return this.http.post<any>(`${this.entityUrl}/register`, {userType, fullName, phone, username, password, companyName, ruc, address, appUrl});
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    localStorage.removeItem('socialNetwork');
    this.store.dispatch(new LoginAction(LoginActionType.unauthenticated));
  }

  // TODO: Implementar el login con OAuth2
  loginWithOAuth2(socialUser: SocialUser) {
    return this.http.post<any>(`${this.entityUrl}/oauth`, socialUser)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('socialNetwork', JSON.stringify(socialUser.provider ? true : false));
        this.store.dispatch(new LoginAction(LoginActionType.authenticated, user));
        return user;
      }));
  }

  verifyToken(token) {
    return this.http.post<any>(`${this.entityUrl}/verify`, {token})
      .pipe(map(user => {
        const innerToken = user.token.split('.')[1];
        const authenticateUser = JSON.parse(atob(innerToken));

        this.store.dispatch(new LoginAction(LoginActionType.authenticated, { token: user.token, id: authenticateUser.id, authenticateUser, realEstate: authenticateUser.propertyCompaniesId, isRealState: !!authenticateUser.propertyCompaniesId }));
        return user;
      }));
  }

  sendRecoveryPassword(username) {
    const appUrl = environment.appUrl;
    return this.http.post<any>(`${this.entityUrl}/recover-pass`, {username, appUrl});
  }

  validateTokenStatus(token) {
    return this.http.post<any>(`${this.entityUrl}/check-token-status`, {token});
  }

  validateRecoverTokenStatus(token) {
    return this.http.post<any>(`${this.entityUrl}/check-recover-token-status`, {token});
  }

  changePasswordByToken(token: string, password: string) {
    return this.http.post<any>(`${this.entityUrl}/change-password-by-token`, {token, password});
  }

}
