import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {environment} from '../environments/environment';
import {AuthGuard} from './shared/guard/auth.guard';

const pathHome = environment.isRealState ? '/login' : '/inicio';
const routes: Routes = [
  {path: '', redirectTo: pathHome, pathMatch: 'full'},
  {path: 'inicio', loadChildren: () => import('src/app/main/home/home.module').then(m => m.HomeModule)},
  {path: 'login', loadChildren: () => import('src/app/main/admin-login/admin-login.module').then(m => m.AdminLoginModule)},
  {path: 'search/:searchValue', loadChildren: () => import('src/app/main/search/search.module').then(m => m.SearchModule)},
  {path: 'detalle/:detailProperty', loadChildren: () => import('src/app/main/detail/detail.module').then(m => m.DetailModule)},
  {path: 'proyecto/:buildingId', loadChildren: () => import('src/app/main/detail-build/detail-build.module').then(m => m.DetailBuildModule)},
  {path: 'comparar', loadChildren: () => import('src/app/main/compare/compare.module').then(m => m.CompareModule)},
  {path: 'perfil', loadChildren: () => import('src/app/main/profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuard]},
  {path: '404', loadChildren: () => import('src/app/main/not-found/not-found.module').then(m => m.AdminLoginModule)},
  {
    path: 'verificacion/:name/:token',
    loadChildren: () => import('src/app/main/verification/verification.module').then(m => m.VerificationModule)
  },
  {
    path: 'recuperar-contrasena/:name/:username/:token',
    loadChildren: () => import('src/app/main/recover-password/recover-password.module').then(m => m.RecoverPasswordModule)
  },
  {
    path: 'publicar',
    loadChildren: () => import('src/app/main/publish/publish.module').then(m => m.PublishModule),
    canActivate: [AuthGuard]
  },
  {path: '**', redirectTo: pathHome}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
