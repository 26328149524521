import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../shared/entities/authentication.service';
import {AuthService, FacebookLoginProvider, GoogleLoginProvider, SocialUser} from 'angularx-social-login';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input()
  closeModalCancel: () => void;

  @Input()
  isModal = true;

  @Input()
  showRegister = true;

  @Input()
  showSocialNetworks = true;

  @Input()
  closeModalSuccess: () => void;

  @Input()
  openRecoverPasswordForm: () => void;

  @Input()
  openRegister: () => void;

  @Input()
  openWish: () => void;

  loginForm: FormGroup;
  message = '';
  resendMail = false;
  authSubscription: Subscription;
  unauthorized = false;

  constructor(private fb: FormBuilder, private authenticationService: AuthenticationService, private authService: AuthService) {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.authSubscription = this.authService.authState.subscribe((user) => {
      if (!user) {
        return;
      }
      this.authenticationService.loginWithOAuth2(user).subscribe(res => {
        this.closeModalSuccess();
      });
    });
  }

  doLogin() {
    this.authService.signOut();
    if (!this.loginForm.valid) {
      this.message = 'Debe ingresar todos los campos';
      return;
    }
    this.unauthorized = false;
    this.authenticationService.login(this.loginForm.value.username, this.loginForm.value.password).subscribe(res => {
      this.closeModalSuccess();
    }, err => {
      if (err.status === 403) {
        this.message = 'El usuario no a verificado el email';
        this.resendMail = true;
      } else if (err.status === 401) {
        this.unauthorized = true;
      }
    });

  }

  loginWithFacebook() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  loginWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  resendVerificationEmail() {
    this.authService.signOut();
    if (!this.loginForm.value.username) {
      this.message = 'Debe ingresar el email';
      return;
    }
    this.authenticationService.reSendVerificationEmail(this.loginForm.value.username).subscribe(res => {
      this.message = `Se envio un mensaje al correo ${this.loginForm.value.username}`;
      this.resendMail = false;
    }, err => {
      if (err.status === 404) {
        this.message = 'El usuario no existe';
      }
    });
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

}
