import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProfilesService} from '../../shared/entities/profiles.service';
import {LoginStore} from '../../shared/redux/login/login-store';

@Component({
  selector: 'app-registration-request',
  templateUrl: './registration-request.component.html',
  styleUrls: ['./registration-request.component.scss']
})
export class RegistrationRequestComponent implements OnInit, OnDestroy {

  @Input()
  closeModalCancelRegistrationRequest: () => void;

  formRequest: FormGroup;
  savingRequest = false;
  saveRequest = false;

  constructor(
    private fb: FormBuilder,
    private profilesService: ProfilesService,
    private store: LoginStore
  ) {
    this.formRequest = this.fb.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern('[0-9]{7,9}')]],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  sendRegistrationRequest() {
    this.savingRequest = true;
    const request = {
      // date: new Date().getTime(),
      name: this.formRequest.value.name,
      phone: this.formRequest.value.phone,
      email: this.formRequest.value.email,
    };
    this.profilesService.sendRegistrationRequest(request).subscribe((res: any) => {
      this.saveRequest = true;
      setTimeout(() => {
        this.closeModalCancelRegistrationRequest();
        }, 3500);
    }, error => {
      this.savingRequest = false;
    });
  }

}
