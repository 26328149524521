import {Injectable} from '@angular/core';
import {Building, ResultSearch} from './search.service';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {PropertyFile} from './property-file.service';
import {Observable} from 'rxjs';

export class DetailsProperties {
  properties: Property;
  buildings: Buildings;
  previews: Previews[];
  thumbs: Thumbs[];
  galleries: Galleries[];
  propertyHappy: number;
  favourite: Favourite;
  publisher: Publisher;

  constructor(properties?: Property, buildings?: Buildings, previews?: Previews[], thumbs?: Thumbs[], galleries?: Galleries[],
              propertyHappy?: number, favourite?: Favourite, publisher?: Publisher) {
    this.properties = properties;
    this.buildings = buildings;
    this.previews = previews;
    this.thumbs = thumbs;
    this.galleries = galleries;
    this.propertyHappy = propertyHappy;
    this.favourite = favourite;
    this.publisher = publisher;
  }
}

export class Favourite {
  favourite: boolean;
  favoritesId: number;

  constructor(favourite: boolean, favoritesId: number) {
    this.favourite = favourite;
    this.favoritesId = favoritesId;
  }
}

export class Publisher {
  name: string;
  url: string;

  constructor(name: string, url: string) {
    this.name = name;
    this.url = url;
  }
}

export class Buildings {
  id: number;
  address: string;
  locationLat: number;
  locationLong: number;
  isPetFriendly: boolean;

  constructor(id: number, address: string, isPetFriendly: boolean, locationLat: number, locationLong: number) {
    this.id = id;
    this.address = address;
    this.locationLat = locationLat;
    this.locationLong = locationLong;
    this.isPetFriendly = isPetFriendly;
  }
}

export class Property {
  id: number;
  buildingId: number;
  title: string;
  review: string;
  description: string;
  currency: string;
  price: number;
  numRooms: number;
  numBathrooms: number;
  numLifts: number;
  maintenanceCost: number;
  useProfessional: boolean;
  useCommercial: boolean;
  videoLink: boolean;
  propertyTypesId: number;
  area: number;
  builtArea: number;
  garage: boolean;
  garages: number;
  garageType: string;
  antiquity: string;
  visits: number;
  favorites: number;
  shared: number;
  buildings: Buildings;
  files: PropertyFile[];
  propertyHappy: string;

  constructor(id: number,
              buildingId: number,
              title?: string,
              review?: string,
              description?: string,
              currency?: string,
              price?: number,
              numRooms?: number,
              numBathrooms?: number,
              area?: number,
              builtArea?: number,
              garage?: boolean,
              garages?: number,
              garageType?: string,
              antiquity?: string,
              visits?: number,
              favorites?: number,
              shared?: number) {
    this.id = id;
    this.buildingId = buildingId;
    this.title = title;
    this.review = review;
    this.description = description;
    this.currency = currency;
    this.price = price;
    this.numRooms = numRooms;
    this.numBathrooms = numBathrooms;
    this.area = area;
    this.builtArea = builtArea;
    this.garage = garage;
    this.garages = garages;
    this.garageType = garageType;
    this.antiquity = antiquity;
    this.visits = visits;
    this.favorites = favorites;
    this.shared = shared;
  }
}

export class Previews {
  id: number;
  url: string;
  name: string;
  description: string;
  extension: string;
  propertiesId: number;

  constructor(id: number, url: string, name: string, description: string, extension: string, propertiesId: number) {
    this.id = id;
    this.url = url;
    this.name = name;
    this.description = description;
    this.extension = extension;
    this.propertiesId = propertiesId;
  }
}

export class Thumbs {
  id: number;
  url: string;
  name: string;
  description: string;
  extension: string;
  propertiesId: number;

  constructor(id: number, url: string, name: string, description: string, extension: string, propertiesId: number) {
    this.id = id;
    this.url = url;
    this.name = name;
    this.description = description;
    this.extension = extension;
    this.propertiesId = propertiesId;
  }
}

export class Galleries {
  id: number;
  url: string;
  name: string;
  description: string;
  extension: string;
  propertiesId: number;

  constructor(id: number, url: string, name: string, description: string, extension: string, propertiesId: number) {
    this.id = id;
    this.url = url;
    this.name = name;
    this.description = description;
    this.extension = extension;
    this.propertiesId = propertiesId;
  }
}

@Injectable({
  providedIn: 'root'
})
export class PropertiesService extends BaseService {

  constructor(protected http: HttpClient) {
    super(http);
    this.basePath = 'properties';
  }

  getExchangeRate() {
    return new Observable<number>((observer) => {
      observer.next(3.78);
      observer.complete();
    });
  }

  getPropertiesByBuildingId(id: number) {
    return this.http.get<Property>(`${this.entityUrl}/building/${id}`).pipe(
      map((response) => {
        return response;
      }),
      catchError(error => error)
    );
  }

  getByID(id: number): Observable<DetailsProperties> {
    return this.http.get<DetailsProperties>(`${this.entityUrl}/${id}`);
  }

  isFavourite(usersId: number, propertiesId: number) {
    return this.http.get<boolean>(`${this.entityUrl}/is-favourite/${usersId}/${propertiesId}`);
  }

  mostVisited(id: number) {
    return this.http.get<any>(`${this.entityUrl}/most-visited/${id}`);
  }

  most4Visited() {
    return this.http.get<any>(`${this.entityUrl}/most-4-visited`);
  }

  updateVisits(id: number) {
    return this.http.get<number>(`${this.entityUrl}/update-visits/${id}`);
  }

  updateShared(propertiesId: number) {
    return this.http.get<number>(`${this.entityUrl}/update-shared/${propertiesId}`);
  }

  updateFavorites(fav: any) {
    return this.http.post<number>(`${this.entityUrl}/update-favorites`, fav);
  }

  updateInterest(interest: any) {
    return this.http.post<any>(`${this.entityUrl}/update-interest`, interest);
  }

}
