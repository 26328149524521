import {BaseService} from './base.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SocialUser} from 'angularx-social-login';
import {environment} from '../../../environments/environment';
import {LoginAction, LoginActionType} from '../redux/login/login-action';
import {LoginStore} from '../redux/login/login-store';

export class User {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  token: string;
}

@Injectable({providedIn: 'root'})
export class AuthenticationService extends BaseService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(protected http: HttpClient, private store: LoginStore) {
    super(http);
    this.basePath = 'authenticate';
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    if (this.currentUserSubject.value) {
      setTimeout(() => {
        this.store.dispatch(new LoginAction(LoginActionType.authenticated));
      });
    }
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username, password) {
    return this.http.post<any>(`${this.entityUrl}`, {username, password})
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.store.dispatch(new LoginAction(LoginActionType.authenticated));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  reSendVerificationEmail(username) {
    const appUrl = environment.appUrl;
    return this.http.post<any>(`${this.entityUrl}/resend-email`, {username, appUrl});
  }

  register(username: string, password: string, fullname: string) {
    const appUrl = environment.appUrl;
    return this.http.post<any>(`${this.entityUrl}/register`, {username, password, fullname, appUrl});
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    localStorage.removeItem('socialNetwork');
    this.currentUserSubject.next(null);
  }

  loginWithOAuth2(socialUser: SocialUser) {
    return this.http.post<any>(`${this.entityUrl}/oauth`, socialUser)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        localStorage.setItem('socialNetwork', JSON.stringify(socialUser.provider ? true : false));
        this.store.dispatch(new LoginAction(LoginActionType.authenticated));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  verifyToken(token) {
    return this.http.post<any>(`${this.entityUrl}/verify`, {token})
      .pipe(map(user => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.store.dispatch(new LoginAction(LoginActionType.authenticated));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  sendRecoveryPassword(username) {
    const appUrl = environment.appUrl;
    return this.http.post<any>(`${this.entityUrl}/recover-pass`, {username, appUrl});
  }

  validateTokenStatus(token) {
    return this.http.post<any>(`${this.entityUrl}/check-token-status`, {token});
  }

  validateRecoverTokenStatus(token) {
    return this.http.post<any>(`${this.entityUrl}/check-recover-token-status`, {token});
  }

  changePasswordByToken(token: string, password: string) {
    return this.http.post<any>(`${this.entityUrl}/change-password-by-token`, {token, password});
  }

}
