import { Component, OnInit } from '@angular/core';
import {LoginAction, LoginActionType} from '../../shared/redux/login/login-action';
import {LoginStore} from '../../shared/redux/login/login-store';
import {Blog, BlogsService} from '../../shared/entities/blogs.service';
import {SearchService} from '../../shared/entities/search.service';
import {stringify} from 'querystring';
import {Router} from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentUser;
  usersId = 0;
  realEstate;
  isRealState = false;
  currentModalId = '';
  entries;
  districts;
  loc = document.location;
  toSearch = `${this.loc.protocol}//${this.loc.hostname}${this.loc.port ? ':' + this.loc.port : ''}`;
  toPublic = `${this.loc.protocol}//${this.loc.hostname}${this.loc.port ? ':' + this.loc.port : ''}` + '/publicar';
  toProfile = `${this.loc.protocol}//${this.loc.hostname}${this.loc.port ? ':' + this.loc.port : ''}` + '/perfil';
  toBlog = 'http://blog.tusgar.com';

  constructor(
    private store: LoginStore,
    private blogsService: BlogsService,
    private searchService: SearchService,
    private router: Router
  ) {
    store.select('payload').subscribe((res) => {
      if (res) {
        this.currentUser = res;
        this.realEstate = res.propertyCompaniesId;
        this.usersId = res.id;
        this.isRealState = res.isRealState;
      }
    });
  }

  ngOnInit() {
    this.blogsService.get().subscribe((res: Blog[]) => {
      if (res.length <= 4) {
        this.entries = res;
      } else {
        this.entries = res.slice(0, 4);
      }
    });
    this.searchService.findDistrictsMoreVisited().subscribe(res => {
      this.districts = res;
    });
  }

  // TUSGAR
  requestRegistration() {
    // this.modalService.close(this.currentModalId);
    this.currentModalId = 'form-registration-request';
    setTimeout(() => {
      this.store.dispatch(new LoginAction(LoginActionType.openRegistrationRequest));
    });
  }

  openRegister() {
    // this.modalService.close(this.currentModalId);
    this.currentModalId = 'register-form';
    setTimeout(() => {
      this.store.dispatch(new LoginAction(LoginActionType.openRegister));
    });
  }

  toMayus(texto): string {
    const re = /(^|[^A-Za-zÁÉÍÓÚÜÑáéíóúüñ])(?:([a-záéíóúüñ])|([A-ZÁÉÍÓÚÜÑ]))|([A-ZÁÉÍÓÚÜÑ]+)/gu;
    return texto.replace(re,
      (m, caracterPrevio, minuscInicial, mayuscInicial, mayuscIntermedias) => {
        const locale = ['es', 'gl', 'ca', 'pt', 'en'];
        if (mayuscIntermedias) {
          return mayuscIntermedias.toLocaleLowerCase(locale);
        }
        return caracterPrevio
          + (minuscInicial ? minuscInicial.toLocaleUpperCase(locale) : mayuscInicial);
      }
    );
  }

  searchByDistrict(row) {
    const extra = '"extra": "' + row.district + '"';
    const district = '"region": "' + row.district + '"';
    const user = '"usersId": ' + this.usersId;
    const logged = '"isLoggedIn": ' + (this.currentUser ? 'true' : 'false');
    const search = '{' + extra + ', ' + district + ', ' + user + ', ' + logged + '}';
    this.router.navigate(['/search', btoa(search)]).then(e => {
      if (e) {
        console.log('Navigation is successful!');
      } else {
        console.log('Navigation has failed!');
      }
    });
  }

}
