import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {InterestComponent} from './interest.component';
import {ReactiveFormsModule} from '@angular/forms';
import {InputControlModule} from "../input-control/input-control.module";



@NgModule({
  imports: [CommonModule, ReactiveFormsModule, InputControlModule],
  declarations: [InterestComponent],
  exports: [InterestComponent]
})
export class InterestModule {}
