import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputControlComponent } from './input-control.component';
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [InputControlComponent],
    imports: [
        CommonModule,
        FormsModule
    ],
  exports: [
    InputControlComponent
  ]
})
export class InputControlModule { }
