import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RegisterComponent} from './register.component';
import {ReactiveFormsModule} from '@angular/forms';
import { SelectControlModule } from '../select-control/select-control.module';
import { InputControlModule } from '../input-control/input-control.module';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, SelectControlModule, InputControlModule],
  declarations: [RegisterComponent],
  exports: [RegisterComponent]
})
export class RegisterModule {
}
