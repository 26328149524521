import {NgModule} from '@angular/core';
import {ProfileMenuComponent} from './profile-menu.component';
import {RouterModule} from '@angular/router';
import {GMenuNameModule} from '../../shared/modules/g-menu-name';


@NgModule({
  declarations: [ProfileMenuComponent],
  imports: [
    RouterModule, GMenuNameModule,
  ],
  exports: [ProfileMenuComponent]
})
export class ProfileMenuModule {
}
