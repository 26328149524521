import { Injectable } from '@angular/core';
import { Observable, pipe, Subject } from 'rxjs';
import { LoginAction, LoginActionType } from './login-action';
import { loginReducer } from './login-reducer';

import { get, isEqual } from 'lodash';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LoginStore {
  state: Observable<LoginAction>;
  actions: Subject<LoginAction> = new Subject();

  constructor() {
    let token = localStorage.getItem('currentUser');
    let authenticateUser = null;
    if (token) {
      const innerToken = token.split('.')[1];

      if (this.isTokenExpired(innerToken)) {
        localStorage.clear();
        token = null;
      } else {
        authenticateUser = JSON.parse(atob(innerToken));
      }
    }

    const initialAction = token
      ? new LoginAction(LoginActionType.authenticated, { token: token, id: authenticateUser.id, authenticateUser, realEstate: authenticateUser.propertyCompaniesId, isRealState: !!authenticateUser.propertyCompaniesId })
      : new LoginAction(LoginActionType.unauthenticated);

    this.state = this.actions.pipe(
      startWith(initialAction),
      loginReducer(),
      shareReplay(1),
    );

  }

  dispatch(action: LoginAction) {
    if (action.type === LoginActionType.authenticated) {
      localStorage.setItem('currentUser', action.payload.token);
    } else if (action.type === LoginActionType.unauthenticated) {
      localStorage.clear();
    }

    this.actions.next(action);
  }

  select(path: string) {
    return this.state.pipe(slice(path));
  }

  private isTokenExpired(token) {
    try {
        // Get the payload part of the JWT (second part)
        const payload = JSON.parse(atob(token));
        
        // Get current timestamp in seconds
        const currentTime = Math.floor(Date.now() / 1000);
        
        // Check if token has expired
        return payload.exp < currentTime;
    } catch (e) {
        return true;
    }
  }
}


export const slice = path =>
  pipe(
    map(state => get(state, path, null)),
    distinctUntilChanged(isEqual)
  );
