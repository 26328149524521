import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LoginStore } from '../redux/login/login-store';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  auth = false
  constructor(
    private store: LoginStore,
    private router: Router
  ) {
    this.store.select('payload').subscribe((res) => {
      if (res) {
        this.auth = true
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth) {
      // authorised so return true
      return true;
    }

    const home = environment.isRealState ? '' : '/';
    // not logged in so redirect to login page with the return url
    this.router.navigate(['']);
    return false;
  }
}
