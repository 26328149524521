
import {Component} from '@angular/core';
import {Router, Event, NavigationEnd} from '@angular/router';
import {AuthenticationService, User} from '../../shared/entities/authentication.service';
import {Observable} from 'rxjs';
import {ModalService} from '../modal/modal.service';
import {LoginStore} from '../../shared/redux/login/login-store';
import {LoginAction, LoginActionType} from '../../shared/redux/login/login-action';
import {AuthService} from 'angularx-social-login';
import {MenuEventsService} from '../../shared/services/menu-events.service';
import {NgForm} from '@angular/forms';
import {SearchService} from '../../shared/entities/search.service';

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss']
})
export class BarComponent {
  user$: User;
  lang$: Observable<any>;
  loginActionType = LoginActionType;
  loginStatus;
  currentModalId = '';
  searching = false;
  viewSearch = false;
  authenticateUser;
  socialNetwork = false;
  realEstate;
  isRealState = false;

  constructor(private router: Router, private authenticationService: AuthenticationService, private modalService: ModalService,
              private store: LoginStore, private authService: AuthService, private menuEventsService: MenuEventsService,
              private searchService: SearchService) {
    
    store.select('payload').subscribe((res) => {
      if (res) {
        this.user$ = res;
        this.realEstate = res.propertyCompaniesId;
        this.isRealState = res.isRealState;
      }
    });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.viewSearch = !(event.url === '/' || event.url === '/inicio' || event.url === '');
      }
    });

    this.loginStatus = store.select('type');
    this.lang$ = store.state;
    this.lang$.subscribe((res: LoginAction) => {
      switch (res.type) {
        case LoginActionType.openRegister:
          this.currentModalId = 'register-form';
          break;
        case LoginActionType.openLogin:
          this.currentModalId = 'login-form';
          break;
        case LoginActionType.openWish:
          this.currentModalId = 'wish-form';
          break;
        case LoginActionType.recoverPassword:
          this.currentModalId = 'recover-password-form';
          break;
        case LoginActionType.openNoResult:
          this.currentModalId = 'no-result';
          break;
        case LoginActionType.openHappinessLevelDetail:
          this.currentModalId = 'happiness-level-detail';
          break;
        case LoginActionType.openInterest:
          this.currentModalId = 'form-interest';
          break;
        case LoginActionType.openRegistrationRequest:
          this.currentModalId = 'form-registration-request';
          break;
        default:
          this.currentModalId = '';
      }
      if (this.currentModalId) {
        this.modalService.open(this.currentModalId);
      }
      this.menuEventsService.reloadActions();
    });
  }

  cancelLogin = () => {
    this.modalService.close(this.currentModalId);
    setTimeout(() => {
      this.store.dispatch(new LoginAction(LoginActionType.unauthenticated));
    });
    // tslint:disable-next-line:semicolon
  };

  successLogin = () => {
    this.modalService.close(this.currentModalId);
    /*setTimeout(() => {
      this.store.dispatch(new LoginAction(LoginActionType.authenticated));
      this.socialNetwork = localStorage.getItem('socialNetwork') === 'true';
    });*/
    // tslint:disable-next-line:semicolon
  };

  openRegisterFromLogin = () => {
    this.modalService.close(this.currentModalId);
    setTimeout(() => {
      this.store.dispatch(new LoginAction(LoginActionType.openRegister));
    });
    // tslint:disable-next-line:semicolon
  };

  openLoginFromRegister = () => {
    this.modalService.close(this.currentModalId);
    setTimeout(() => {
      this.store.dispatch(new LoginAction(LoginActionType.openLogin));
    });
    // tslint:disable-next-line:semicolon
  };

  openWishFromRegister = () => {
    this.modalService.close(this.currentModalId);
    setTimeout(() => {
      this.store.dispatch(new LoginAction(LoginActionType.openWish));
    });
    // tslint:disable-next-line:semicolon
  };

  openRecoverPasswordForm = () => {
    this.modalService.close(this.currentModalId);
    setTimeout(() => {
      this.store.dispatch(new LoginAction(LoginActionType.recoverPassword));
    });
    // tslint:disable-next-line:semicolon
  };

  showLoginForm() {
    this.store.dispatch(new LoginAction(LoginActionType.openLogin));
  }

  showWishForm() {
    this.store.dispatch(new LoginAction(LoginActionType.openWish));
  }

  showRegisterForm() {
    this.store.dispatch(new LoginAction(LoginActionType.openRegister));
  }

  doLogout() {
    this.authenticationService.logout();
    this.authService.signOut();
    setTimeout(() => {
      this.store.dispatch(new LoginAction(LoginActionType.unauthenticated));
      const loc = document.location;
      document.location.href = `${loc.protocol}//${loc.hostname}${loc.port ? ':' + loc.port : ''}`;
    });

  }

  onSubmit(f: NgForm) {
    this.searching = true;
    if (!f.valid) {
      this.searching = false;
      return;
    }
    this.searchService.getFormParams(f.value.searchValue).subscribe(res => {

      // this.router.navigate(['/search', btoa(JSON.stringify(res))]).then(e => {
      this.router.navigate(['/search', btoa(JSON.stringify(res))]).then(e => {
        if (e) {
          console.log('Navigation is successful!');
          this.searching = false;
        } else {
          console.log('Navigation has failed!');
          this.searching = false;
        }
      });
    });
  }

}
