import {Injectable} from '@angular/core';
import {Observable, pipe, Subject} from 'rxjs';
import {LoginAction, LoginActionType} from './login-action';
import {loginReducer} from './login-reducer';

import {distinctUntilChanged, map, shareReplay, startWith, tap} from 'rxjs/operators';
import {get, isEqual} from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class LoginStore {
  state: Observable<LoginAction>;
  actions: Subject<LoginAction> = new Subject();

  constructor() {
    this.state = this.actions.pipe(
      startWith(new LoginAction(LoginActionType.unauthenticated)),
      loginReducer(),
      shareReplay(1),
    );

  }

  dispatch(action: LoginAction) {
    this.actions.next(action);
  }

  select(path: string) {
    return this.state.pipe(slice(path));
  }
}


export const slice = path =>
  pipe(
    map(state => get(state, path, null)),
    distinctUntilChanged(isEqual)
  );
