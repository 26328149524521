import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AuthService, FacebookLoginProvider, GoogleLoginProvider} from 'angularx-social-login';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../shared/entities/authentication.service';
import {Subscription} from 'rxjs';

const PasswordValidation = [
  Validators.required,
  Validators.minLength(6),
  Validators.maxLength(24),
];
const EmailValidation = [Validators.required, Validators.email];

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  @Input()
  closeModalCancel: () => void;

  @Input()
  closeModalSuccess: () => void;

  @Input()
  openRecoverPasswordForm: () => void;

  @Input()
  openLogin: () => void;

  authSubscription: Subscription;
  registerForm: FormGroup;
  message: string;
  verificationSent = false;

  constructor(private fb: FormBuilder, private authenticationService: AuthenticationService, private authService: AuthService) {
    this.registerForm = this.fb.group({
      fullname: ['', [Validators.required]],
      username: ['', EmailValidation],
      password: ['', PasswordValidation],
      password2: ['', [Validators.required]],
      conditions: [false, [Validators.required]],
    }, {validator: this.checkPasswords});
  }

  ngOnInit() {
    this.authSubscription = this.authService.authState.subscribe((user) => {
      if (!user) {
        return;
      }

      this.authenticationService.loginWithOAuth2(user).subscribe(res => {
        this.closeModalSuccess();
      });

    });
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.get('password').value;
    const confirmPass = group.get('password2').value;

    return pass === confirmPass ? null : {notSame: true};
  }

  loginWithFacebook() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  loginWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  registerUser() {

    if (this.registerForm.errors && this.registerForm.errors.notSame) {
      this.message = 'Las contraseñas no coinciden';
      return;
    }

    if (this.registerForm.invalid) {
      this.message = 'Debe ingresar todos los campos';
      return;
    }

    if (!this.registerForm.value.conditions) {
      this.message = 'Debe aceptar los terminos y condiciones de servicio';
      return;
    }

    this.authenticationService.register(
      this.registerForm.value.username,
      this.registerForm.value.password,
      this.registerForm.value.fullname,
    ).subscribe( (res) => {
        this.verificationSent = !this.verificationSent;
      },
      // this.closeModalSuccess,
      (err) => {
        if (err.error) {
          this.message = err.error;
        }
      });
  }

}
