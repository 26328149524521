import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BarModule} from './components/bar/bar.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TugsarInputComponent} from './components/tugsar-input/tugsar-input.component';
import {ProfileMenuModule} from './components/profile-menu/profile-menu.module';
import {FooterModule} from './components/footer/footer.module';
import {ErrorInterceptor} from './shared/interceptors/error.interceptor';
import {JwtInterceptor} from './shared/interceptors/jwt.intercepto';
import {AuthServiceConfig, SocialLoginModule} from 'angularx-social-login';
import {provideConfig} from './config/auth.service.config';
import {DragDropFileUploadDirective} from './shared/directives/dragAndDropFileUpload';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    TugsarInputComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BarModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ProfileMenuModule,
    FooterModule,
    SocialLoginModule,
    SharedModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: AuthServiceConfig, useFactory: provideConfig}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
