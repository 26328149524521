import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectControlComponent } from './select-control.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [SelectControlComponent],
    imports: [
        CommonModule,
        FormsModule
    ],
  exports: [
    SelectControlComponent
  ]
})
export class SelectControlModule { }
