import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {Ubigeo} from './ubigeo.service';

export const ProfilePagination = {size: 10};

export const ProfilePaginationDashboard = {size: 5};

@Injectable({
  providedIn: 'root'
})
export class ProfilesService extends BaseService {

  constructor(protected http: HttpClient) {
    super(http);
    this.basePath = 'profiles';
  }

  getRegion() {
    return this.http.get<Ubigeo[]>(`${this.entityUrl}/ubigeo`);
  }

  getByIdparent(idparent: number) {
    return this.http.get<Ubigeo>(`${this.entityUrl}/ubigeo/${idparent}`);
  }

  getByUserInfoId() {
    return this.http.get<any>(`${this.entityUrl}`);
  }

  saveUserProfile(userProfile: any) {
    return this.http.post<any>(`${this.entityUrl}`, userProfile);
  }

  getFavorites(page: number) {
    return this.http.get<any>(`${this.entityUrl}/activity/favorites`, {
      params: {
        page: `${page}`,
        size: `${ProfilePagination.size}`
      }
    });
  }

  getContacted(page: number) {
    return this.http.get<any>(`${this.entityUrl}/activity/contacted`, {
      params: {
        page: `${page}`,
        size: `${ProfilePagination.size}`
      }
    });
  }

  getVisited(page: number) {
    return this.http.get<any>(`${this.entityUrl}/activity/visited`, {
      params: {
        page: `${page}`,
        size: `${ProfilePagination.size}`
      }
    });
  }

  getAlerts(page: number) {
    return this.http.get<any>(`${this.entityUrl}/activity/alerts`, {
      params: {
        page: `${page}`,
        size: `${ProfilePagination.size}`
      }
    });
  }

  getHappinessAbilityByUserInfoId() {
    return this.http.get<any>(`${this.entityUrl}/happiness-ability`);
  }

  saveHappinessAbility(happinessHability: any) {
    return this.http.post<any>(`${this.entityUrl}/happiness-ability`, happinessHability);
  }

  savePassword(password: any) {
    return this.http.post<any>(`${this.entityUrl}/save-password`, password);
  }

  removeFavorites(favoritesId, page: number) {
    return this.http.get<any>(`${this.entityUrl}/activity/remove-favorites/${favoritesId}`, {
      params: {
        page: `${page}`,
        size: `${ProfilePagination.size}`
      }
    });
  }

  removeVisited(visitedId, page: number) {
    return this.http.get<any>(`${this.entityUrl}/activity/remove-visited/${visitedId}`, {
      params: {
        page: `${page}`,
        size: `${ProfilePagination.size}`
      }
    });
  }

  removeAlerts(alertId, page: number) {
    return this.http.get<any>(`${this.entityUrl}/activity/alerts/remove-alert/${alertId}`, {
      params: {
        page: `${page}`,
        size: `${ProfilePagination.size}`
      }
    });
  }

  removeAllVisited() {
    return this.http.get<any>(`${this.entityUrl}/activity/remove-all-visited`);
  }

  changeFrequencyAlerts(alertId: number) {
    return this.http.get<number>(`${this.entityUrl}/activity/alerts/chage-frequency/${alertId}`);
  }

  sendRegistrationRequest(request) {
    return this.http.post<any>(`${this.entityUrl}/send-registration-request`, request);
  }

  getTotalesDashboard() {
    return this.http.get<any>(`${this.entityUrl}/dashboard/totals`);
  }

  getLastVisits(page: number) {
    return this.http.get<any>(`${this.entityUrl}/dashboard/last-visits`, {
      params: {
        page: `${page}`,
        size: `${ProfilePaginationDashboard.size}`
      }
    });
  }

  getLastAlerts(page: number) {
    return this.http.get<any>(`${this.entityUrl}/dashboard/last-alerts`, {
      params: {
        page: `${page}`,
        size: `${ProfilePaginationDashboard.size}`
      }
    });
  }

  getPublications(page: number) {
    return this.http.get<any>(`${this.entityUrl}/announcement/publications`, {
      params: {
        page: `${page}`,
        size: `${ProfilePagination.size}`
      }
    });
  }

}
