import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {RecoverPassComponent} from './recover-pass.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [RecoverPassComponent],
  exports: [RecoverPassComponent]
})
export class RecoverPasswordModule { }
