export enum LoginActionType {
  openLogin = 'OPEN-LOGIN',
  openWish = 'OPEN-WISH',
  openRegister = 'OPEN-REGISTER',
  unauthenticated = 'UNAUTHENTICATED',
  authenticated = 'AUTHENTICATED',
  loggingIn = 'LOGGING-IN',
  recoverPassword = 'RECOVER-PASSWORD',
  openNoResult = 'NO-RESULT',
  openHappinessLevelDetail = 'HAPPINESS_LEVEL_DETAIL',
  openInterest = 'FORM_INTEREST',
  openRegistrationRequest = 'REGISTRATION_REQUEST'
}

export class LoginAction {
  constructor(public type: LoginActionType, public payload?: any) {
  }
}
