import {Inject, NgModule, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class GMenuNameModule {
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: string
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    setTimeout(() => {
      this.createMenus();
    });
  }

  public createMenus() {
    if (this.isBrowser) {
      $('.g-menu-name').off();
      $('.g-menu-name').on('click', function() {
        $(this).toggleClass('active');

        const iconArrowBottom = 'icon-arrow-bottom';
        const iconArrowTop = 'icon-arrow-top';

        if ($(this).hasClass('active')) {
          $(this).next().slideDown(500);
          $(this).find('.' + iconArrowBottom).addClass(iconArrowTop).removeClass(iconArrowBottom);
        } else {
          $(this).find('.' + iconArrowTop).addClass(iconArrowBottom).removeClass(iconArrowTop);
          $(this).next().slideUp();
        }
      });
    }

  }
}
