import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-profile-meu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit {

  socialNetwork = localStorage.getItem('socialNetwork') === 'true' ? true : false;
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  realEstate;
  isRealState = false;

  constructor() {
    const innerToken = this.currentUser.token.split('.')[1];
    this.realEstate = JSON.parse(atob(innerToken)).propertyCompaniesId;
    this.isRealState = this.realEstate ? true : false;
  }

  ngOnInit() {
  }

}
