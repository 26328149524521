import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';

export class Building {

  id: number;
  lat: number;
  lng: number;
  price: number;
  district: string;
  numBath: number;
  numRooms: number;
  area: number;
  garages: number;
  propertiesId: number;
  antiquity: number;
  isPetFriendly: boolean;
  defaultThumbnail: boolean;
  currency: string;
  address: string;
  propertyType: string;
  active: boolean;
  happiness: number;
  isFavorite: boolean;
  innerProperties: number;
  isProject: boolean;
  title: string;
  image: string;

  constructor(
    id: number,
    lat: number,
    lng: number,
    price: number,
    district: string,
    numBath: number,
    numRooms: number,
    area: number,
    propertiesId: number,
    garages: number,
    antiquity: number,
    isPetFriendly: boolean,
    defaultThumbnail: boolean,
    currency: string,
    address: string,
    propertyType: string,
    happiness: number,
    isFavorite: boolean,
    innerProperties: number,
    isProject: boolean,
    title?: string,
    image?: string
  ) {
    this.id = id;
    this.lat = lat;
    this.lng = lng;
    this.price = price;
    this.district = district;
    this.numBath = numBath;
    this.numRooms = numRooms;
    this.area = area;
    this.propertiesId = propertiesId;
    this.garages = garages;
    this.antiquity = antiquity;
    this.isPetFriendly = isPetFriendly;
    this.defaultThumbnail = defaultThumbnail;
    this.currency = currency;
    this.address = address;
    this.propertyType = propertyType;
    this.happiness = happiness;
    this.isFavorite = isFavorite;
    this.innerProperties = innerProperties;
    this.isProject = isProject;
    this.title = title;
    this.image = image;
  }
}

export class ResultSearch {
  buildings: any[][] | Building[];
  tags: string[];
}

export class SearchForm {
  propertyTypeId: number;
  currency: string;
  minPrice: number;
  maxPrice: number;
  region: number;
  bedroomsMin: number;
  bedrooms: number;
  bathroomsMin: number;
  bathrooms: number;
  areaMin: number;
  area: number;
  antiquity: number;
  commonAreas: number;
  parkingLotsMin: number;
  parkingLots: number;
  isLoggedIn: boolean;
  usersId: number;
}

@Injectable({
  providedIn: 'root'
})
export class SearchService extends BaseService {

  constructor(protected http: HttpClient) {
    super(http);
    this.basePath = 'search';
  }

  getFormParams(searchedValue: string, option?: string) {
    return this.http.get<SearchForm>(`${this.entityUrl}/get-form-params`, { params: { q: searchedValue, t: option } });
  }

  saveSearch(searchedValue: SearchForm) {
    Object.keys(searchedValue).forEach(key => {
      if (searchedValue[key] == null) {
        delete searchedValue[key];
      }
    });
    return this.http.get<boolean>(`${this.entityUrl}/map/save`, {params: searchedValue as any});
  }

  findDistrictsMoreVisited() {
    return this.http.get<any>(`${this.entityUrl}/districts-more-visited`);
  }

  search(searchedValue: SearchForm) {
    Object.keys(searchedValue).forEach(key => {
      if (searchedValue[key] == null) {
        delete searchedValue[key];
      }
    });

    return this.http.get<ResultSearch>(`${this.entityUrl}/map`, {params: searchedValue as any}).pipe(
      map((response) => {

        // @ts-ignore
        response.buildings = response.buildings.map<Building[]>((building: any[]) => new Building(
          building[0],
          building[1],
          building[2],
          building[3],
          building[4],
          building[5],
          building[6],
          building[7],
          building[8],
          building[9],
          building[10],
          building[11],
          building[12],
          building[13],
          building[14],
          building[15],
          building[16],
          building[17],
          building[18],
          building[19],
          building[20] ? building[20] : null,
          building[21] ? building[21] : null,
          )
        );
        return response;
      }),
      catchError(error => error)
    );
  }
}
