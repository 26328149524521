import {AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, LoginOpt} from 'angularx-social-login';


const fbLoginOptions: LoginOpt = {
  scope: 'email',
  return_scopes: true,
  enable_profile_selector: true
}; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11
// 142614408040-k4krgf2ln4gi49fe2a1b6t4jh6sbq03v.apps.googleusercontent.com
// gdqmsMmTPSTk5UUgnM3HnTPF
const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('766064684895-pu1clj4j4ppehqj7so0ui8omrkec9lqs.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('206109043774108', fbLoginOptions)
  }
]);

export function provideConfig() {
  return config;
}
