import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DetailsProperties, PropertiesService} from '../../shared/entities/properties.service';
import {LoginAction, LoginActionType} from '../../shared/redux/login/login-action';
import {LoginStore} from '../../shared/redux/login/login-store';
import {toNumbers} from '@angular/compiler-cli/src/diagnostics/typescript_version';
import {stringify} from 'querystring';

@Component({
  selector: 'app-interest',
  templateUrl: './interest.component.html',
  styleUrls: ['./interest.component.scss']
})
export class InterestComponent implements OnInit, OnDestroy {

  @Input()
  closeModalCancelInterest: () => void;

  propertiesId: number;
  formInterest: FormGroup;
  savingInterest = false;
  saveInterest = false;
  currentModalId = '';

  constructor(
    private fb: FormBuilder,
    private propertiesService: PropertiesService,
    private store: LoginStore
  ) {
    this.propertiesId = parseInt(localStorage.getItem('interestPropertiesId'), 10);
    this.formInterest = this.fb.group({
      msg: ['Hola, me interesa la casa y me gustaría hacer una visita.\n' +
      'Un saludo', [Validators.required]],
      name: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern('[0-9]{7,9}')]],
      email: ['', [Validators.required, Validators.email]],
      propertiesId: [this.propertiesId, [Validators.required]],
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  updateInterest() {
    this.savingInterest = true;
    const link = 'http://' + location.hostname + (location.port ? ':' + location.port : '') +
      '/detalle/' + btoa(JSON.stringify(this.propertiesId));
    const interest = {
      propertiesId: this.propertiesId,
      date: new Date().getTime(),
      name: this.formInterest.value.name,
      phone: this.formInterest.value.phone,
      email: this.formInterest.value.email,
      msg: this.formInterest.value.msg,
      link
    };
    this.propertiesService.updateInterest(interest).subscribe((res: any) => {
      this.formInterest.patchValue({
        msg: 'Hola, me interesa la casa y me gustaría hacer una visita.\n' +
          'Un saludo', name: '', phone: '', email: ''
      });
      this.saveInterest = true;
      setTimeout(() => {
        this.closeModalCancelInterest();
        }, 2500);
    }, error => {
      this.savingInterest = false;
      this.openLoginFromRegister();
    });
  }

  openLoginFromRegister = () => {
    // this.modalService.close(this.currentModalId);
    this.currentModalId = 'login-form';
    setTimeout(() => {
      this.store.dispatch(new LoginAction(LoginActionType.openLogin));
    });
    // tslint:disable-next-line:semicolon
  };

}
