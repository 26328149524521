import {NgModule} from '@angular/core';
import {BarComponent} from './bar.component';
import {CommonModule} from '@angular/common';
import {ModalModule} from '../modal/modal.module';
import {LoginModule} from '../login/login.module';
import {RegisterModule} from '../register/register.module';
import {WishModule} from '../wish/wish.module';
import {GMenuNameModule} from '../../shared/modules/g-menu-name';
import {RouterModule} from '@angular/router';
import {RecoverPasswordModule} from '../recover-pass/recover-pass.module';
import {NoDataModule} from '../no-data/no-data.module';
import {HappinessLevelDetailModule} from '../happiness-level-detail/happiness-level-detail.module';
import {FormsModule} from '@angular/forms';
import {InterestModule} from '../interest/interest.module';
import {RegistrationRequestModule} from '../registration-request/registration-request.module';

@NgModule({
  declarations: [BarComponent],
  imports: [
    CommonModule,
    ModalModule,
    LoginModule,
    RegisterModule,
    WishModule,
    GMenuNameModule,
    RouterModule,
    LoginModule,
    RecoverPasswordModule,
    NoDataModule,
    HappinessLevelDetailModule,
    FormsModule,
    InterestModule,
    RegistrationRequestModule
  ],
  exports: [BarComponent]
})
export class BarModule {
}
