/* tslint:disable:semicolon */
import {Component, Input, forwardRef} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-select-control',
  templateUrl: './select-control.component.html',
  styleUrls: ['./select-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectControlComponent),
      multi: true
    }
  ]
})
export class SelectControlComponent implements ControlValueAccessor {
  @Input() Label = '';
  @Input() ValueKey = '';
  @Input() LabelKey = '';
  @Input() options: any[] = [];
  @Input() isDisabled: boolean = false;
  @Input() isInvalid: boolean
  @Input() control: FormControl;

  selectedValue: any;

  constructor() {
  }

  onChange = (_: any) => { };

  onTouched = () => { };

  valueChanged(value: any) {
    this.onChange(value);
    this.onTouched();
  }

  writeValue(value: any): void {
    this.selectedValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

}
