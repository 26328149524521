import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from '../entities/authentication.service';
import {environment} from '../../../environments/environment';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      console.log(err);
      if (err.status === 401 &&
        err.url !== `${environment.tusgarApi1}authenticate` &&
        err.url !== `${environment.tusgarApi1}resend-email`) {

        this.authenticationService.logout();
        location.reload(true);
      }

      return throwError(err);
    }));
  }
}
