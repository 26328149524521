import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';

export class Blog {
  link: string;
  title: string;
  content: string;
  image: string;

  constructor(link: string, title: string, content: string, image: string) {
    this.link = link;
    this.title = title;
    this.content = content;
    this.image = image;
  }
}

@Injectable({
  providedIn: 'root'
})
export class BlogsService extends BaseService {

  constructor(protected http: HttpClient) {
    super(http);
    this.basePath = 'blog';
  }

  get() {
    return this.http.get<Blog[]>(`${this.entityUrl}`).pipe(
      map((response) => {
        return response;
      }),
      catchError(error => error)
    );
  }

}
