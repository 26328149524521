import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NoDataComponent} from './no-data.component';
import {ReactiveFormsModule} from '@angular/forms';



@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [NoDataComponent],
  exports: [NoDataComponent]
})
export class NoDataModule {}
