import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  baseUrl = environment.tusgarApi1;

  constructor(protected http: HttpClient) {}

  basePath = '';

  Get(filter?: any) {
    return this.http.get(`${this.baseUrl}${this.basePath}`, {params: filter});
  }

  get entityUrl(): string {
    return this.baseUrl + this.basePath;
  }

  Save(entity) {
    if (entity.id) {
      return this.Update(entity);
    } else {
      return this.Add(entity);
    }
  }

  Add(entity) {
    return this.http.post(`${this.baseUrl}${this.basePath}`, entity);
  }

  Update(entity) {
    return this.http.put(`${this.baseUrl}${this.basePath}/${entity.id}`, entity);
  }

  Delete(entity) {
    return this.http.delete(`${this.baseUrl}${this.basePath}/${entity.id}`);
  }

}
