import {Component, OnInit} from '@angular/core';
import { LoginStore } from 'src/app/shared/redux/login/login-store';

@Component({
  selector: 'app-profile-meu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit {

  socialNetwork = localStorage.getItem('socialNetwork') === 'true' ? true : false;
  currentUser;
  realEstate;
  isRealState = false;

  constructor(store: LoginStore) {
    store.select('payload').subscribe((res) => {
      if (res) {
        this.currentUser = res;
        this.realEstate = res.propertyCompaniesId;
        this.isRealState = res.isRealState;
      }
    });
  }

  ngOnInit() {
  }

}
