import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HappinessLevelDetailComponent} from './happiness-level-detail.component';
import {ReactiveFormsModule} from '@angular/forms';



@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [HappinessLevelDetailComponent],
  exports: [HappinessLevelDetailComponent]
})
export class HappinessLevelDetailModule {}
