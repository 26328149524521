import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginStore } from '../redux/login/login-store';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  token

  constructor(private store: LoginStore) {
    this.store.select('payload').subscribe((res) => {
      if (res) {
        this.token = res.token;
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`
        }
      });
    }

    return next.handle(request);
  }
}
