import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {WishComponent} from './wish.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [WishComponent],
  exports: [WishComponent]
})
export class WishModule { }
