import {Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {log} from 'util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'tusgar';
  isFullPage = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    const firstChildData$ = this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(e => {
        return e;
      }));

    firstChildData$.subscribe(res => {
      if (window.location.pathname === '/login') {
        this.isFullPage = true;
      } else {
        this.isFullPage = false;
      }
      // this.isFullPage = (res.urlAfterRedirects.substr(0, 6) === '/login' && res.url === '/') || res.url === '/login';
    });
  }

}
